import React from 'react'
import { Link } from 'gatsby'

const BlogTeaser = ({ slug, title, summary, date }) => (
	<div className="blog--teaser main">
		<header className="major">
			<Link to={slug}>
				<h2>{title}</h2>
			</Link>
			<i>
				<p className="publication-date">
					{ date }
				</p>
			</i>
		</header>
		<p dangerouslySetInnerHTML={{ __html: summary }} />
		<ul className="actions">
			<li className="more-link">
				<Link to={slug}>
					<em>Weiterlesen</em><i
                className="icon fa-long-arrow-right"
                style={{ marginLeft:`0.5em`, color:`#ffa728` }}
              ></i>
				</Link>
			</li>
		</ul>
	</div>
)

export default BlogTeaser