import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import { Waypoint } from 'react-waypoint'
import Nav from '../components/Nav'
import BlogTeaser from '../components/BlogTeaser'

class BlogPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Crabber-Webservice - Blog" />
        <HeaderGeneric title="Blog" subtitle="Crabbers Welt" />
        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} active="blog" />
        <div id="main">
          <div className="main">
            <StaticQuery
              query = {graphql`
                query Query {
                  allNodeArticle(sort: {fields: created, order: DESC}, filter: {status: {eq: true}}) {
                    edges {
                      node {
                        fields {
                          slug
                        }
                        id
                        title
                        body {
                          processed
                          summary
                        }
                        created(formatString: "DD.MM.YYYY", locale: "de")
                      }
                    }
                  }
                }
              `}
              render = {data => (
                data.allNodeArticle.edges.map(blog => (
                  <BlogTeaser
                    key={blog.node.id}
                    slug={blog.node.fields.slug}
                    title={blog.node.title}
                    date={blog.node.created}
                    summary={blog.node.body.summary?blog.node.body.summary.substring(0,600):blog.node.body.processed.substring(0, 600)}
                  />
                ))
              )}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPage
